import { createAction, props } from '@ngrx/store';
import { ClinicDto } from '../../shared/models/franchise/franchise.model';

export const loadClinics = createAction('[Clinic] Load Clinics');
export const loadClinicsSuccess = createAction(
  '[Clinic] Load Clinics Success',
  props<{ clinics: ClinicDto[] | null }>()
);
export const loadClinicsFailure = createAction(
  '[Clinic] Load Clinics Failure',
  props<{ error: any }>()
);
